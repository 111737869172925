<template>
  <div class="CANCELED" />
  <!--
  <span>
    <base-section id="mnnPresentation">
      <v-container>
        <v-row
          align="center"
          justify="center"
        >
          <base-img
            :src="require(`@/assets/imgs/mnn/logo.png`)"
            color="grey lighten-1"
            max-width="192"
            tile
          />
        </v-row>
      </v-container>
      <base-section-heading
        color="primary"
        title=" "
        outlined
      >
        {{ $i18n.t('minnovi') }}
        <br>
        <base-btn
          class="mt-12"
          outlined
          @click="openPage"
        >
          {{ $i18n.t('discover-more') }}
        </base-btn>
      </base-section-heading>

      <v-container>
        <v-row>
          <v-col
            v-for="(card, i) in fullCards.slice(0, fullCards.length - 1)"
            :key="i"
            cols="12"
            sm="12"
            md="4"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <base-img
                  :key="i"
                  style="cursor: pointer"
                  :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
                  :src="require(`@/assets/imgs/mnn/img-${i+1}.png`)"
                  color="grey lighten-1"
                  flat
                  tile
                  @click="openPage"
                >
                  <v-overlay
                    :key="i"
                    :value="hover"
                    absolute
                    class="pa-6"
                    color="secondary"
                    opacity=".9"
                  >
                    <base-subheading :title="card.title" />

                    <base-body>
                      {{ card.text }}
                    </base-body>
                  </v-overlay>
                </base-img>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
    <base-section id="mnnPresentation_amministro">
      <v-container>
        <v-row
          align="center"
          justify="center"
        >
          <base-img
            :src="require(`@/assets/imgs/mnn/logo_amministro.png`)"
            color="grey lighten-1"
            max-width="192"
            tile
          />
        </v-row>
      </v-container>
      <base-section-heading
        title=" "
        outlined
      >
        {{ $i18n.t('amministro') }}
        <br>
        <base-btn
          class="mt-12"
          outlined
          @click="openPageAmm"
        >
          {{ $i18n.t('discover-more') }}
        </base-btn>
      </base-section-heading>

      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <base-img
                  style="cursor: pointer"
                  :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
                  :src="require(`@/assets/imgs/mnn/imgAmm-1.jpg`)"
                  color="grey lighten-1"
                  flat
                  tile
                  @click="openPageAmm"
                >
                  <v-overlay
                    :value="hover"
                    absolute
                    class="pa-6"
                    color="secondary"
                    opacity=".9"
                  >
                    <base-subheading :title="fullCards[fullCards.length - 1].title" />
                    <base-body>
                      {{ fullCards[fullCards.length - 1].text }}
                    </base-body>
                  </v-overlay>
                </base-img>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </span>
  -->
</template>

<script>
  export default {
    name: 'SgdPresentation',
    // *************
    // ** DATA    **
    // *************
    data: () => ({
      cards: 4,
    }),
    computed: {
      fullCards () {
        const ncards = []
        for (let i = 0; i < this.cards; i++) {
          ncards.push({
            title: this.$i18n.t(`t${i}.title`),
            text: this.$i18n.t(`t${i}.text`),
          })
        }
        return ncards
      },
    },
    // *************
    // ** METHODS **
    // *************
    methods: {
      openPage () {
        window.open('https://minnovi.com/', '_blank')
      },
      openPageAmm () {
        window.open('https://minnovi.com/landing-page/', '_blank')
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"amministro": "Amministro.io is the innovative solution to optimize condominium and administrative management and simplify life within the condominium. It is designed to speed up the work of the administrator, facilitate that of the supplier and guarantee transparency and serenity to the condominiums.",
		"discover-more": "Find out more",
		"minnovi": "Minnovi is a software house in the province of Treviso, specialized in the creation of management software in the cloud, including Amministro.io for real estate management. For more than ten years it has been simplifying work flows with specific solutions for administrative, documentary and operational management, accessible from desktop and mobile devices.",
		"t0": {
			"text": "Conception, design and implementation of software in collaboration with the customer",
			"title": "Software development"
		},
		"t1": {
			"text": "Creation of management software in the cloud to improve administration and operations",
			"title": "Cloud Management software"
		},
		"t2": {
			"text": "Development of applications for Android and iOS to reduce costs and increase business efficiency",
			"title": "Mobile apps"
		},
		"t3": {
			"text": "The innovative solution to optimize condominium management",
			"title": "Ammministro.io"
		}
	},
	"it": {
		"amministro": "Amministro.io è la soluzione innovativa per ottimizzare la gestione condominiale e amministrativa e semplificare la vita all'interno del condominio. È pensato per velocizzare il lavoro dell'amministratore, facilitare quello del fornitore e garantire trasparenza e serenità ai condòmini.",
		"discover-more": "Scopri di più",
		"minnovi": "Minnovi è una software house in provincia di Treviso, specializzata nella realizzazione di software gestionali in cloud, tra cui Amministro.io per la gestione immobiliare. Da più di dieci anni semplifica i flussi di lavoro con soluzioni specifiche per la gestione amministrativa, documentale e operativa, accessibili sia da dispositivi fissi che mobile.",
		"t0": {
			"text": "Ideazione, progettazione e realizzazione di software in collaborazione col cliente finale",
			"title": "Sviluppo software"
		},
		"t1": {
			"text": "Creazione di gestionali in cloud per migliorare l’amministrazione e l’operatività",
			"title": "Gestionali in cloud"
		},
		"t2": {
			"text": "Sviluppo di applicazioni per Android e iOS per ridurre i costi e aumentare l’efficienza aziendale",
			"title": "App per dispositivi mobile"
		},
		"t3": {
			"text": "La soluzione innovativa per ottimizzare la gestione condominiale",
			"title": "Amministro.io"
		}
	}
}
</i18n>
